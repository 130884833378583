@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:"";content:none}table{border-collapse:collapse;border-spacing:0}body>iframe{z-index:-1 !important}
div,p,span{font-family:Lato,"Lato",Inter,"Inter",sans-serif !important;font-size:13px}html,body,#root{height:100%;background:#f6f6f6 !important}button,input{border:none;outline:none;box-shadow:none;border-radius:0;color:inherit;line-height:normal;overflow:visible;background-color:rgba(0,0,0,0)}button:focus,input:focus{outline:none;box-shadow:none}input[type=number]{-moz-appearance:textfield}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none}
* {
	font-family: "Lato", Lato, "Inter", Inter, sans-serif !important;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 1366px;
}

a:hover,
a:focus {
	text-decoration: none !important;
}

path:focus {
	outline: none;
	box-shadow: none;
}

div:focus {
	outline: none;
	box-shadow: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.leaflet-pane {
	z-index: 10;
}

body > .dragging-layer {
  position: absolute !important;
  z-index: 9999;
  overflow: visible;
  margin: 0;
  padding: 0;
  border: none;
  pointer-events: none;
  opacity: 0.6;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  --default-background-color: #fff;
  /* dragging element */
  /* icon */
}
body > .dragging-layer > *:first-child {
  pointer-events: none !important;
  overflow: hidden !important;
  transform: translate(-50%, -50%) !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 8px rgba(0, 0, 0, 0.1);
}
body > .dragging-layer > .dragging {
  /* prevent double translucent layers */
  opacity: 1 !important;
}
body > .dragging-layer > div:last-child {
  position: absolute;
  left: 12px;
  top: 12px;
}
.drag-initiator {
  user-select: none;
  -webkit-user-select: none;
  touch-action: none;
}
.drag-accept-reject::after {
  content: "🚫";
}
.dock {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
}
.dock-bar {
  font-size: 14px;
  line-height: 1.5;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  outline: none;
  zoom: 1;
}
.dock-ink-bar {
  position: absolute;
  box-sizing: border-box;
  margin-top: -3px;
  background-color: #108ee9;
  transform-origin: 0 0;
  width: 0;
  height: 0;
}
.dock-tab-btn-disabled {
  cursor: default;
  color: #ccc;
}
.dock-nav-animated {
  transition: transform 0.25s cubic-bezier(0.35, 0, 0.25, 1);
}
.dock-tab {
  box-sizing: border-box;
  position: relative;
  display: block;
  min-width: 30px;
  height: 30px;
  text-align: center;
  transition: color 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  padding: 0;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  float: left;
  margin-right: 10px;
  background: #fafafd;
  outline: none;
}
.dock-tab.dragging {
  opacity: 0.3;
}
.dock-tab > div {
  padding: 4px 14px;
  outline-color: #108ee9;
}
.dock-tab:nth-last-child(2) .dock-tab-hit-area {
  right: -999999px;
}
.dock-tab:hover {
  color: #349fec;
}
.dock-tab-active,
.dock-tab-active:hover {
  color: #108ee9;
  cursor: default;
  transform: translateZ(0);
}
.dock-tab-disabled {
  cursor: default;
  color: #ccc;
}
.dock-tab-disabled:hover {
  color: #ccc;
}
.dock-content-animated {
  transition: margin 0.3s;
}
.dock-bottom > .dock-nav .dock-ink-bar-animated,
.dock-top > .dock-nav .dock-ink-bar-animated,
.dock-bottom > div > .dock-nav .dock-ink-bar-animated,
.dock-top > div > .dock-nav .dock-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.dock-content-animated .dock-tabpane {
  flex-shrink: 0;
}
.no-flexbox .dock-content {
  transform: none !important;
  overflow: auto;
}
.dock-tabpane:not(.dock-tabpane-active) {
  animation-name: dock-fadeOut;
  animation-duration: 0.5s;
}
@keyframes dock-fadeOut {
  from {
    height: 100%;
    visibility: visible;
  }
  to {
    height: 100%;
    visibility: visible;
  }
}
.dock-top {
  flex-direction: column;
}
.dock-top .dock-bar {
  flex: 0 0 auto;
  background: #fafafd;
  border-bottom: 1px solid #f3f3f3;
  padding-left: 8px;
}
.dock-top .dock-content-animated {
  flex-direction: row;
}
.dock-top .dock-tab-next {
  right: 2px;
}
.dock-top .dock-ink-bar {
  height: 2px;
  bottom: 0;
  left: 0;
}
.dock-tab-close-btn {
  position: absolute;
  cursor: pointer;
  font-family: 'Fredoka One', sans-serif;
  color: #ddd;
  right: -3px;
  font-size: 12px;
  width: 16px;
  text-align: center;
  top: 6px;
  outline: none;
  transition: all 0.25s ease-in-out;
}
.dock-tab-close-btn:before {
  content: "X";
}
.dock-tab-close-btn:hover,
.dock-tab-close-btn:focus {
  color: #666;
  transform: scale(1.1, 1.1);
}
.dock-tab:hover .dock-tab-close-btn,
.dock-tab-close-btn:focus {
  opacity: 1;
  color: #a2a2a2;
}
.dock-tab-hit-area {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.dock-pane-cache {
  width: 100%;
  height: 100%;
}
.dock-content {
  display: flex;
  width: 100%;
  height: 100%;
}
.dock-content-holder {
  flex: auto;
}
.dock-top > .dock-content-holder {
  /* work around for the height issue in content holder*/
  height: 0;
}
.dock-content .dock-tabpane {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  outline: none;
  flex: none;
}
.dock-nav {
  display: flex;
  flex: none;
  position: relative;
}
.dock-nav-operations {
  display: flex;
}
.dock-nav-more {
  height: 30px;
  border: none;
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  cursor: pointer;
  position: relative;
  align-self: flex-end;
}
.dock-nav-more::after {
  position: absolute;
  z-index: 1;
  content: '';
  pointer-events: none;
  width: 30px;
  height: 30px;
  left: -30px;
  top: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.dock-nav-wrap {
  position: relative;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.dock-nav-list {
  display: flex;
  position: relative;
  transition: transform 0.3s;
}
.dock-extra-content {
  display: flex;
  height: 30px;
}
.dock-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.dock-dropdown-hidden {
  display: none;
}
.dock-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dock-dropdown-menu-item {
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
}
.dock-dropdown-menu-item .dock-tab-hit-area {
  left: 0;
  right: 0;
}
.dock-dropdown-menu-item .dock-tab-close-btn {
  right: 2px;
}
.dock-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.dock-dropdown-menu-item-disabled,
.dock-dropdown-menu-item-disabled:hover {
  color: #ccc;
  background: 0 0;
  cursor: not-allowed;
}
body.dock-dragging .dock-tab-hit-area {
  pointer-events: inherit;
}
body.dock-dragging {
  user-select: none;
  -webkit-user-select: none;
}
body.dock-dragging iframe {
  pointer-events: none;
}
.dock-panel {
  box-sizing: border-box;
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border: 1px solid #ddd;
}
.dock-panel.dragging {
  opacity: 0.3;
}
.dock-panel-drag-size {
  position: absolute;
  z-index: 300;
}
.dock-panel-drag-size-t {
  top: -4px;
  left: 4px;
  right: 4px;
  height: 8px;
  cursor: ns-resize;
}
.dock-panel-drag-size-b {
  bottom: -4px;
  left: 4px;
  right: 4px;
  height: 8px;
  cursor: ns-resize;
}
.dock-panel-drag-size-l {
  left: -4px;
  top: 4px;
  bottom: 4px;
  width: 8px;
  cursor: ew-resize;
}
.dock-panel-drag-size-r {
  right: -4px;
  top: 4px;
  bottom: 4px;
  width: 8px;
  cursor: ew-resize;
}
.dock-panel-drag-size-t-l {
  top: -4px;
  left: -4px;
  width: 8px;
  height: 8px;
  cursor: nwse-resize;
}
.dock-panel-drag-size-t-r {
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  cursor: nesw-resize;
}
.dock-panel-drag-size-b-l {
  bottom: -4px;
  left: -4px;
  width: 8px;
  height: 8px;
  cursor: nesw-resize;
}
.dock-panel-drag-size-b-r {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3E%3Cpath fill='rgba(0, 0, 0, 0.1)' d='M2 12 L12 2 L12 12z'/%3E%3C/svg%3E");
  bottom: -4px;
  right: -4px;
  width: 17px;
  height: 17px;
  cursor: nwse-resize;
}
.dock-box {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
}
.divider-box {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
}
.divider-box > *:nth-child(odd) {
  flex: 1 1 auto;
}
.dock-divider {
  box-sizing: border-box;
  flex: 0 0 4px;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
}
.dock-hbox > .dock-divider {
  transform: scaleX(3);
  cursor: ew-resize;
}
.dock-vbox {
  flex-direction: column;
}
.dock-vbox > .dock-divider {
  transform: scaleY(3);
  cursor: ns-resize;
}
.dock-fbox {
  pointer-events: none;
  z-index: 200;
}
.dock-fbox > .dock-panel {
  position: absolute;
  pointer-events: visible;
  box-shadow: 0 0 4px #aaaaaa;
}
.dock-fbox > .dock-panel.dragging {
  opacity: 0.8;
  pointer-events: none;
}
.dock-mbox {
  z-index: 250;
  width: 100%;
  height: 100%;
}
.dock-mbox > .dock-panel {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 4px #aaaaaa;
}
.dock-mbox.dock-mbox-show {
  visibility: visible;
  animation: dock-mbox-show 0.2s ease;
}
.dock-mbox.dock-mbox-hide {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  animation: dock-mbox-hide 0.2s ease;
}
@keyframes dock-mbox-show {
  from {
    transform: scale(0.9, 0.9);
    visibility: visible;
  }
  to {
    transform: scale(1, 1);
    visibility: visible;
  }
}
@keyframes dock-mbox-hide {
  from {
    transform: scale(1, 1);
    visibility: visible;
    opacity: 1;
  }
  to {
    transform: scale(0.9, 0.9);
    visibility: visible;
    opacity: 0;
  }
}
.dock-wbox {
  position: absolute;
  width: 100%;
  height: 100%;
}
.dock-wbox > .dock-panel {
  border: none;
  width: 100%;
  height: 100%;
}
.dock-layout {
  overflow: hidden;
  position: relative;
}
.dock-layout > .dock-box {
  position: absolute;
  width: 100%;
  height: 100%;
}
.dock-layout > .dock-drop-indicator {
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 400;
  border: solid 1px #fff;
  background: #88c7f4;
  opacity: 0.5;
  box-shadow: 0 0 4px #ddd;
  display: none;
  transition: all 0.1s ease-out;
}
.dock-drop-edge {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: white;
  border: none;
  opacity: 0.01;
}
.dock-drop-layer {
  position: absolute;
  pointer-events: none;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
}
.dock-drop-layer .dock-drop-square {
  position: absolute;
  box-sizing: border-box;
  pointer-events: visible;
  z-index: 300;
  width: 32px;
  height: 32px;
  font-family: 'Fredoka One', sans-serif;
  color: #ddd;
  background: #fff;
  border: 1px solid transparent;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
}
.dock-drop-layer .dock-drop-square .dock-drop-square-box {
  box-sizing: border-box;
  border: 1px solid #ddd;
  width: 100%;
  height: 100%;
}
.dock-drop-layer .dock-drop-square::before {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  line-height: 30px;
  text-align: center;
}
.dock-drop-layer .dock-drop-float {
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  opacity: 0.01;
}
.dock-drop-layer .dock-drop-left {
  left: calc(50% - 48px);
}
.dock-drop-layer .dock-drop-left.dock-drop-deep {
  left: calc(50% - 64px);
  width: 16px;
}
.dock-drop-layer .dock-drop-left::before {
  content: '>';
  transform: rotate(180deg);
}
.dock-drop-layer .dock-drop-right {
  left: calc(50% + 16px);
}
.dock-drop-layer .dock-drop-right.dock-drop-deep {
  left: calc(50% + 48px);
  width: 16px;
}
.dock-drop-layer .dock-drop-right::before {
  content: '>';
}
.dock-drop-layer .dock-drop-top {
  top: calc(50% - 48px);
}
.dock-drop-layer .dock-drop-top.dock-drop-deep {
  top: calc(50% - 64px);
  height: 16px;
}
.dock-drop-layer .dock-drop-top.dock-drop-deep::before {
  line-height: 14px;
}
.dock-drop-layer .dock-drop-top::before {
  content: '>';
  transform: rotate(270deg);
}
.dock-drop-layer .dock-drop-bottom {
  top: calc(50% + 16px);
}
.dock-drop-layer .dock-drop-bottom.dock-drop-deep {
  top: calc(50% + 48px);
  height: 16px;
}
.dock-drop-layer .dock-drop-bottom.dock-drop-deep::before {
  line-height: 14px;
}
.dock-drop-layer .dock-drop-bottom::before {
  content: '>';
  transform: rotate(90deg);
}
.dock-drop-layer .dock-drop-square-dropping {
  background: #88c7f4;
  color: white;
}
.dock-panel.dock-panel-dropping .dock-tab-close-btn {
  pointer-events: none;
}
.dock-panel-max-btn,
.dock-panel-min-btn {
  height: 15px;
  width: 15px;
  font-family: 'Fredoka One', sans-serif;
  margin: 2px 2px 2px 0;
  padding: 6px 4px 4px 2px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  outline: none;
  -webkit-user-select: none;
          user-select: none;
}
.dock-panel-max-btn:before,
.dock-panel-min-btn:before {
  position: absolute;
  content: " ";
  border: 2px solid #ddd;
  border-radius: 1px;
  width: 9px;
  height: 9px;
}
.dock-panel-max-btn:hover,
.dock-panel-min-btn:hover,
.dock-panel-max-btn:focus,
.dock-panel-min-btn:focus {
  color: #a2a2a2;
}
.dock-panel-max-btn:hover:before,
.dock-panel-min-btn:hover:before,
.dock-panel-max-btn:focus:before,
.dock-panel-min-btn:focus:before {
  border-color: #a2a2a2;
}
.dock-panel-max-btn:hover,
.dock-panel-min-btn:hover {
  transform: scale(1.1);
}
.dock-panel-min-btn:before {
  position: absolute;
  border-radius: 1px;
  width: 9px;
  height: 9px;
  border: none;
  content: "-";
  color: #666;
  font-size: 20px;
  line-height: 4px;
}
.dock-panel-min-btn:hover:before,
.dock-panel-min-btn:focus:before {
  color: #999;
}
.dock-panel.dock-style-place-holder {
  border: none;
}
.dock-panel.dock-style-place-holder .dock-bar {
  display: none;
}
.dock-layout > :not(.dock-fbox) .dock-panel.dock-style-headless {
  border: none;
}
.dock-layout > :not(.dock-fbox) .dock-panel.dock-style-headless .dock-bar {
  position: absolute;
  z-index: 1;
  opacity: 0;
  height: 20px;
  width: 100%;
  transition: all 0.15s ease-in-out;
}
.dock-layout > :not(.dock-fbox) .dock-panel.dock-style-headless .dock-bar:hover,
.dock-layout > :not(.dock-fbox) .dock-panel.dock-style-headless .dock-bar:focus-within,
.dock-layout > :not(.dock-fbox) .dock-panel.dock-style-headless.dock-panel-dropping .dock-bar {
  opacity: 1;
  height: 31px;
}
.dock-layout > :not(.dock-fbox) .dock-panel.dock-style-headless .dock-content {
  height: 100%;
}
.dock-panel.dock-style-main {
  border: none;
}
.dock-panel.dock-style-main .dock-bar {
  background: none;
  border-bottom: 1px solid #eee;
}
.dock-panel.dock-style-main .dock-tab {
  background: #fff;
}
.dock-panel.dock-style-card .dock-tab {
  margin-right: 2px;
  border: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
}
.dock-panel.dock-style-card .dock-tab.dock-tab-active {
  border-bottom: 1px solid #fff;
  background: #fff;
}
.dock-panel.dock-style-card .dock-bar {
  border-bottom: 1px solid #ddd;
  overflow: visible;
}
.dock-panel.dock-style-card .dock-nav-wrap {
  padding-top: 5px;
  transform: translate(0, 1px);
}
.dock-panel.dock-style-card .dock-ink-bar {
  /* hide animated ink bar */
  background: #fff;
  opacity: 0;
}
.dock-panel.dock-style-card .dock-tab-hit-area {
  /* cover the border area */
  left: -2px;
  right: -2px;
}
.dock-panel.dock-style-card .dock-panel-max-btn,
.dock-panel.dock-style-card .dock-panel-min-btn {
  margin-top: 4px;
}
.dock-panel.dock-style-card .dock-tab-close-btn {
  right: 0;
  font-size: 10px;
  top: 8px;
}
.dock-panel.dock-style-card .dock-extra-content {
  height: 35px;
}

.PointModal_wrapper__2F15B {
	position: fixed;
	z-index: 9;
	width: 1100px;
	background: white;
	box-shadow: 0px 6px 20px 4px rgb(0 0 0 / 30%);
	border-radius: 5px;
	animation: PointModal_opacity__1Lkgy 0.4s;
}

.PointModal_displayFlexSpaceB__2aUQb {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.PointModal_content__6jjN9 {
	width: 1100px;
	height: 500px;
	background: white;
}

.PointModal_historyTable__jVDTE {
	display: flex;
	width: 100%;
	height: 150px;
	grid-gap: 8px;
	gap: 8px;
}

.PointModal_row__1qX-- {
	display: flex;
	width: 100%;
	grid-gap: 8px;
	gap: 8px;
}

.PointModal_streetRow__2NUDN {
	/* height: 100%; */
}

.PointModal_row__1qX-- > div:first-child {
	width: 100%;
}

.PointModal_row__1qX-- > div:last-child {
	width: 30%;
}

.PointModal_fullSizeRow__KCEg2 {
	display: flex;
	width: 100%;
	grid-gap: 8px;
	gap: 8px;
}

.PointModal_selectedItem__1e3Z- {
	height: 32px;
	width: 100% !important;
	padding: 5px 7px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.PointModal_fieldsContainer__2hqwY {
	width: 40%;
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
	padding: 8px;
}

.PointModal_mapContainer__1W9gn {
	width: 80%;
	height: 100%;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	position: relative;
}

.PointModal_mapHeader__1ZNxD {
	height: 43px;
	display: flex;
	align-items: center;
}

.PointModal_headerContent__R90dl {
	width: 95%;
}

.PointModal_header__1mbju {
	width: 893px;
	height: 31px;
	background-color: #21333f;
	border-radius: 5px 5px 0px 0px;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	cursor: default;
	padding: 0 5px;
	color: #ffffff;
}

.PointModal_button__1MKss {
	width: 16px;
	cursor: pointer;
}

.PointModal_dropButton__7Zbr9 {
	width: 12px;
	height: 12px;
	cursor: grab;
}

.PointModal_page__2Pl0D {
	padding: 0 !important;
}

.PointModal_closeButton__2AJfb {
	fill: #9ba3a8;
}

.PointModal_footer__1E6GI {
	width: 100%;
	border-radius: 0 0 5px 5px;
	background-color: white;
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	justify-content: flex-end;
	padding: 8px;
}

@keyframes PointModal_opacity__1Lkgy {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.PointModal_headerCell__1Z9xW {
	font-weight: bold !important;
	font-size: 11px;
	line-height: 13px;
	height: 26px;
	background: #ebebeb !important;
	color: #21333f !important;
}

.PointModal_index__7e9OZ {
	width: 80px;
}

.PointModal_tableRow__3sKTl:hover {
	background: #f2f2f2;
}

.PointModal_tableRow__3sKTl {
	height: 26px !important;
}

.PointModal_table__Vw45N {
	max-height: 300px;
	margin: 0 0 !important;
}

.PointModal_tableContainer__2lxtl {
	height: 180px;
	overflow-y: scroll;
}

